import { template as template_aad1d49d5b0e4bbeb7625187252ad1bb } from "@ember/template-compiler";
const WelcomeHeader = template_aad1d49d5b0e4bbeb7625187252ad1bb(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
