import { template as template_caf67af4dd454921b6776d60fb5a3027 } from "@ember/template-compiler";
const FKControlMenuContainer = template_caf67af4dd454921b6776d60fb5a3027(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
