import { template as template_bad7419203a94804a1933d9041e20550 } from "@ember/template-compiler";
const SidebarSectionMessage = template_bad7419203a94804a1933d9041e20550(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
